/**
 * PermissionKey
 * @file Permission keys enumeration definition
 */

export const PermissionKey = {
  /**
   * 维保单核准报价
   */
  MAINT_ORDER_QUOTE_AUDIT: 'MAINT_ORDER_QUOTE_AUDIT',

  /**
   * 维保单更换核准人
   */
  MAINT_ORDER_REPLACE_AUDITOR: 'MAINT_ORDER_REPLACE_AUDITOR',

  /**
   * 维保单锁定和解锁
   */
  MAINT_ORDER_LOCK_UNCLOCK: 'MAINT_ORDER_LOCK_UNCLOCK',

  /**
   * 维保单终审复核权限，拥有该权限可见复核按钮、进行复核操作。
   */
  MAINT_ORDER_RECHECK: 'MAINT_ORDER_RECHECK',

  /**
   * 待复核维保单导出权限
   */
  MAINT_ORDER_RECHECK_DISPATCHED_EXPORT:
    'MAINT_ORDER_RECHECK_DISPATCHED_EXPORT',
  /**
   * 待复核维保单导入权限
   */
  MAINT_ORDER_RECHECK_DISPATCHED_IMPORT:
    'MAINT_ORDER_RECHECK_DISPATCHED_IMPORT',

  /**
   * 服务站扣款明细权限[菜单]，拥有该权限的用户可以访问菜单，并进入到扣款明细页面。
   */
  STATION_AMERCEMENT_DETAIL_BILLING: 'STATION_AMERCEMENT_DETAIL_BILLING',

  /**
   * 费用账单权限[菜单]，拥有该权限的用户可以访问菜单，并进入到费用账单页面。
   */
  BILLING_VIEW: 'BILLING_VIEW',

  /**
   * 服务站扣款操作（新增/撤销）权限
   */
  STATION_AMERCEMENT_UPSERT: 'STATION_AMERCEMENT_UPSERT',

  /**
   * 服务站查看扣款 权限
   */
  STATION_AMERCEMENT_VIEW: 'STATION_AMERCEMENT_VIEW',

  /**
   * 导入服务站扣款（财务专用）
   */
  STATION_AMERCEMENT_BATCH_CREATE: 'STATION_AMERCEMENT_BATCH_CREATE',

  /**
   * 车辆管理，更新车队小组导入权限
   */
  VEHICLE_TEAM_GROUP_IMPORT: 'VEHICLE_TEAM_GROUP_IMPORT',

  /**
   * 车队管理，关联车辆权限
   */
  VEHICLE_TEAM_GROUP_ASSOCIATED: 'VEHICLE_TEAM_GROUP_ASSOCIATED',

  /**
   * 车队管理，关联车辆权限
   */
  VEHICLE_TEAM_GROUP_EDIT: 'VEHICLE_TEAM_GROUP_EDIT',

  /**
   * 车辆管理，维护车辆司机权限。
   */
  VEHICLE_DRIVER_MAINTAIN: 'VEHICLE_DRIVER_MAINTAIN',

  /**
   * 车辆管理，维护车辆行驶证。
   */
  VEHICLE_LICENSE_EDIT: 'VEHICLE_LICENSE_EDIT',

  /**
   * 车辆管理，编辑车辆详细信息。
   */
  VEHICLE_DETAIL_EDIT: 'VEHICLE_DETAIL_EDIT',

  /**
   * 上传/更新行驶证（车队业务）。
   */
  VEHICLE_LIC_IMG_IMPORT: 'VEHICLE_LIC_IMG_IMPORT',

  /**
   * 车辆管理，编辑车队归属。
   */
  VEHICLE_TEAM_OWNERSHIP_CHANGE: 'VEHICLE_TEAM_OWNERSHIP_CHANGE',

  /**
   * 车辆维保，更换站点权限
   */
  MAINT_ORDER_STATION_CHANGE: 'MAINT_ORDER_STATION_CHANGE',

  /**
   * 系统设置菜单权限
   */
  SYSTEM_SETTING: 'SYSTEM_SETTING',

  /**
   * 车队管理，维护保外加价比例字段权限。
   */
  VEHICLE_TEAM_MAINT_UNCOVERED_PIP_OPERATE:
    'VEHICLE_TEAM_MAINT_UNCOVERED_PIP_OPERATE',

  /**
   * 服务站管理，保证金收取确认。
   */
  STATION_CONFIRM_RECEIVED_MARGIN_PAYMENT:
    'STATION_CONFIRM_RECEIVED_MARGIN_PAYMENT',

  /**
   * 对账单菜单权限
   */
  STATION_MONTHLY_STATEMENT_MENU: 'STATION_MONTHLY_STATEMENT_MENU',

  /**
   * 对账单，确认对账单。
   */
  STATION_MONTHLY_STATEMENT_PLATFORM_CONFIRM:
    'STATION_MONTHLY_STATEMENT_PLATFORM_CONFIRM',

  /**
   * 对账单，对账单作废重新生成。
   */
  STATION_MONTHLY_STATEMENT_REGENERATE: 'STATION_MONTHLY_STATEMENT_REGENERATE',

  /**
   * 对账单，对账单结算。
   */
  STATION_MONTHLY_STATEMENT_SETTLE: 'STATION_MONTHLY_STATEMENT_SETTLE',

  /**
   * 对账单，总包对账单导出。
   */
  STATION_MONTHLY_STATEMENT_PLATFORM_EXPORT:
    'STATION_MONTHLY_STATEMENT_PLATFORM_EXPORT',

  /**
   * 对账单，服务站对账单导出。
   */
  STATION_MONTHLY_STATEMENT_STATION_EXPORT:
    'STATION_MONTHLY_STATEMENT_STATION_EXPORT',

  /**
   * 对账单，对账单费用导入。
   */
  STATION_MONTHLY_STATEMENT_FEE_IMPORT: 'STATION_MONTHLY_STATEMENT_FEE_IMPORT',

  /**
   * 对账单，对账单凭证提交。
   */
  STATION_MONTHLY_STATEMENT_COMMIT_INVOICE:
    'STATION_MONTHLY_STATEMENT_COMMIT_INVOICE',

  /**
   * 对账单，总包对账单确认函下载。
   */
  STATION_MONTHLY_STATEMENT_CONFIRM_DOWNLOAD:
    'STATION_MONTHLY_STATEMENT_CONFIRM_DOWNLOAD',

  /**
   * 对账单，服务站对账单确认。
   */
  STATION_MONTHLY_STATEMENT_STATION_CONFIRM:
    'STATION_MONTHLY_STATEMENT_STATION_CONFIRM',

  /**
   * 结算账单（菜单）
   */
  MAINT_SETTLEMENT_ORDER: 'MAINT_SETTLEMENT_ORDER',

  /**
   * 外部账单（菜单）
   */
  EXTERNAL_STATEMENT_ORDER_MENU: 'EXTERNAL_STATEMENT_ORDER_MENU',

  /**
   * 保外账单（菜单）
   */
  MAINT_PRE_QUOTATION_SETTLEMENT_MENU: 'MAINT_PRE_QUOTATION_SETTLEMENT_MENU',

  /**
   * 保外账单导入对账状态
   */
  MAINT_PRE_QUOTATION_SETTLEMENT_IMPORT_STATEMENT:
    'MAINT_PRE_QUOTATION_SETTLEMENT_IMPORT_STATEMENT',

  /**
   * 保外账单导入结算状态
   */
  MAINT_PRE_QUOTATION_SETTLEMENT_IMPORT_SETTLED:
    'MAINT_PRE_QUOTATION_SETTLEMENT_IMPORT_SETTLED',

  /**
   * 保外账单导出明细
   */
  MAINT_PRE_QUOTATION_SETTLEMENT_EXPORT:
    'MAINT_PRE_QUOTATION_SETTLEMENT_EXPORT',

  /**
   * 结算账单-导入结算状态
   */
  IMPORT_MAINT_SETTLEMENT_INFO: 'IMPORT_MAINT_SETTLEMENT_INFO',

  /**
   * 结算账单-导出明细
   */
  EXPORT_MAINT_SETTLEMENT_ORDER: 'EXPORT_MAINT_SETTLEMENT_ORDER',

  /**
   * 外部账单-导入外部账单
   */
  EXTERNAL_STATEMENT_ORDER_PLATFORM_IMPORT:
    'EXTERNAL_STATEMENT_ORDER_PLATFORM_IMPORT',

  /**
   * 外部账单-导出明细
   */
  EXTERNAL_STATEMENT_ORDER_PLATFORM_EXPORT:
    'EXTERNAL_STATEMENT_ORDER_PLATFORM_EXPORT',

  /**
   * 外部账单-导出明细
   */
  EXTERNAL_STATEMENT_ORDER_STATION_EXPORT:
    'EXTERNAL_STATEMENT_ORDER_STATION_EXPORT',

  /**
   * 服务站签约确认和服务站收款信息变更确认。
   */
  STATION_SIGN_CONTRACT_CONFIRM: 'STATION_SIGN_CONTRACT_CONFIRM',

  /**
   * 服务站暂停和恢复派单
   */
  STATION_SUSPEND_RESUME_DISPATCH: 'STATION_SUSPEND_RESUME_DISPATCH',

  /**
   * 服务站基础信息导入
   */
  STATION_BASE_INFO_IMPORT: 'STATION_BASE_INFO_IMPORT',

  /**
   * 工时价格操作权限
   */
  STATION_WORKINGHOUR_PRICE_OPERATE: 'STATION_WORKINGHOUR_PRICE_OPERATE',

  /**
   * 配件价格操作权限
   */
  STATION_FITTING_PRICE_OPERATE: 'STATION_FITTING_PRICE_OPERATE',

  /**
   * 勾选了本权限的用户组用户可以看到实用工具菜单
   */
  UTILITY_TOOLS: 'UTILITY_TOOLS',

  /**
   * 勾选本权限的用户组用户可以有权限对服务站余额进行扣减操作。
   */
  STATION_BALANCE_DEDUCTION: 'STATION_BALANCE_DEDUCTION',

  /**
   * 勾选本权限的用户组用户可以有权限查看服务站余额扣减日志
   */
  STATION_BALANCE_DEDUCTION_SNAPSHOT: 'STATION_BALANCE_DEDUCTION_SNAPSHOT',

  /**
   * 勾选本权限的用户组用户可以有权限设置申通结算凭证
   */
  SHENTONG_SETTLED_VOUCHER_SETTING: 'SHENTONG_SETTLED_VOUCHER_SETTING',
  /**
   * 服务站-批量维保打黄油
   */
  STATION_BATCH_MAINT_BUTTER: 'STATION_BATCH_MAINT_BUTTER',

  /**
   * 标记无效及恢复车辆
   */
  VEHICLE_EFFECTIVE_AND_INEFFECTIVE: 'VEHICLE_EFFECTIVE_AND_INEFFECTIVE',

  /**
   * 车队小组保外加价比例导入
   */
  VEHICLE_GROUP_STATION_MAINT_UNCOVERED_PIP_IMPORT:
    'VEHICLE_GROUP_STATION_MAINT_UNCOVERED_PIP_IMPORT',

  /**
   * 车队小组保外加价比例导出
   */
  VEHICLE_GROUP_STATION_MAINT_UNCOVERED_PIP_EXPORT:
    'VEHICLE_GROUP_STATION_MAINT_UNCOVERED_PIP_EXPORT',

  /**
   * 车队保外协议价导入
   */
  VEHICLE_TEAM_MAINT_UNCOVERED_OFFER_PRICE_IMPORT:
    'VEHICLE_TEAM_MAINT_UNCOVERED_OFFER_PRICE_IMPORT',

  /**
   * 车队保外协议价导出
   */
  VEHICLE_TEAM_MAINT_UNCOVERED_OFFER_PRICE_EXPORT:
    'VEHICLE_TEAM_MAINT_UNCOVERED_OFFER_PRICE_EXPORT',

  /**
   * 车辆承保记录导出
   */
  VEHICLE_COVER_HISTORY_EXPORT: 'VEHICLE_COVER_HISTORY_EXPORT',

  /**
   * 工时库操作权限
   */
  WORKINGHOUR_MANAGE_EDIT: 'WORKINGHOUR_MANAGE_EDIT',

  /**
   * 配件库操作权限
   */
  FITTING_MANAGE_EDIT: 'FITTING_MANAGE_EDIT',

  /**
   * 型号库操作权限
   */
  FITTING_MODEL_MANAGE_EDIT: 'FITTING_MODEL_MANAGE_EDIT',

  /**
   * 故障库操作权限
   */
  FAULT_MANAGE_EDIT: 'FAULT_MANAGE_EDIT',

  /**
   * 维保单备注
   */
  MAINT_ORDER_REMARK_CREATE: 'MAINT_ORDER_REMARK_CREATE',

  /**
   * 更新承保记录
   */
  VEHICLE_MAINT_COVER_HISTORY_EDIT: 'VEHICLE_MAINT_COVER_HISTORY_EDIT',

  /**
   * 车辆里程人工校准操作权限
   * */
  VEHICLE_MILEAGE_CALIBRATION: 'VEHICLE_MILEAGE_CALIBRATION',

  /**
   * 导入车辆
   */
  VEHICLE_LICENSE_ADD: 'VEHICLE_LICENSE_ADD',

  /**
   * 抽佣设置菜单权限
   */
  STATION_COMMISSION_RULE_MENU: 'STATION_COMMISSION_RULE_MENU',

  /**
   * 抽佣设置操作权限
   */
  STATION_COMMISSION_RULE_OPERATE: 'STATION_COMMISSION_RULE_OPERATE',

  /**
   * 生产数据处理
   */
  PRODUCTION_DATA_PROCESS: 'PRODUCTION_DATA_PROCESS',

  /**
   * 系统日志
   */
  SYSTEM_LOG: 'SYSTEM_LOG',

  /**
   * 核准报价后作废
   */
  MAINT_ORDER_REVOKE_AFTER_QUOTE_ACCEPTED:
    'MAINT_ORDER_REVOKE_AFTER_QUOTE_ACCEPTED',

  /**
   * 核准报价后作废日志
   */
  MAINT_ORDER_REVOKE_AFTER_QUOTE_SNAPSHOT:
    'MAINT_ORDER_REVOKE_AFTER_QUOTE_SNAPSHOT',

  /**
   * 查看维保单费用明细
   */
  VEHICLE_TEAM_MAINT_UNCOVERED_QUOTATION_AMOUNT_VIEW:
    'VEHICLE_TEAM_MAINT_UNCOVERED_QUOTATION_AMOUNT_VIEW',

  /**
   * 查看维保单费用合计
   */
  VEHICLE_TEAM_MAINT_UNCOVERED_QUOTATION_TOTAL_AMOUNT_VIEW:
    'VEHICLE_TEAM_MAINT_UNCOVERED_QUOTATION_TOTAL_AMOUNT_VIEW',
  /**
   * 查看付款明细
   */
  MAINT_ORDER_PAYMENT_SUMMARY_DETAIL_QUERY:
    'MAINT_ORDER_PAYMENT_SUMMARY_DETAIL_QUERY',
  /**
   * 申请公对公付款
   */
  MAINT_ORDER_PAYMENT_SUMMARY_CORPORATION_TRANSFER:
    'MAINT_ORDER_PAYMENT_SUMMARY_CORPORATION_TRANSFER',
  /**
   * 备用金账户付款
   */
  PETTY_CASH_ACCOUNT_PAYMENT: 'PETTY_CASH_ACCOUNT_PAYMENT',
  /**
   * 提交审核
   */
  MAINT_ORDER_PAYMENT_SUMMARY_SUBMIT_AUDIT:
    'MAINT_ORDER_PAYMENT_SUMMARY_SUBMIT_AUDIT',
  /**
   * 付款审核
   */
  MAINT_ORDER_PAYMENT_SUMMARY_AUDIT: 'MAINT_ORDER_PAYMENT_SUMMARY_AUDIT',
  /**
   * 公对公付款核销
   */
  MAINT_ORDER_PAYMENT_SUMMARY_CORPORATION_VERIFY:
    'MAINT_ORDER_PAYMENT_SUMMARY_CORPORATION_VERIFY',
  /**
   * 批量维保
   */
  MAINT_REQUEST_IMPORT: 'MAINT_REQUEST_IMPORT',
  /**
   * 备用金账户汇总及明细导出
   */
  USER_PETTY_CASH_SUMMARY_EXPORT: 'USER_PETTY_CASH_SUMMARY_EXPORT',
  /**
   * 直供件销售订单菜单
   */
  SALES_ORDER_MENU: 'SALES_ORDER_MENU',

  /**
   * 新增/修改销售订单
   */
  SALES_ORDER_CREATE_EDIT: 'SALES_ORDER_CREATE_EDIT',

  /**
   * 销售订单导出
   */
  SALES_ORDER_EXPORT: 'SALES_ORDER_EXPORT',

  /**
   * 销售订单发货
   */
  SALES_ORDER_DELIVER: 'SALES_ORDER_DELIVER',

  /**
   * 作废销售订单
   */
  SALES_ORDER_REVOKE: 'SALES_ORDER_REVOKE',

  /**
   * 服务站配件商城
   */
  STATION_FITTING_SHOPPING_MALL: 'STATION_FITTING_SHOPPING_MALL',

  /**
   * 线下收款登记
   */
  SALES_ORDER_COLLECTION_REGISTRATION: 'SALES_ORDER_COLLECTION_REGISTRATION',

  /**
   * 直供件采购订单菜单
   */
  PURCHASE_ORDER_MENU: 'PURCHASE_ORDER_MENU',

  /**
   * 新增采购订单
   */
  PURCHASE_ORDER_CREATE: 'PURCHASE_ORDER_CREATE',

  /**
   * 确认收货
   */
  PURCHASE_ORDER_CONFIRM_RECEIPT: 'PURCHASE_ORDER_CONFIRM_RECEIPT',

  /**
   * 作废订单
   */
  PURCHASE_ORDER_REVOKE: 'PURCHASE_ORDER_REVOKE',

  /**
   * 完成付款
   */
  PURCHASE_ORDER_PAYMENT_REGISTER: 'PURCHASE_ORDER_PAYMENT_REGISTER',

  /**
   * 采购单导出
   */
  PURCHASE_ORDER_EXPORT: 'PURCHASE_ORDER_EXPORT',

  /**
   * 供应商管理菜单
   */
  FITTING_SUPPLIER_MENU: 'FITTING_SUPPLIER_MENU',
  /**
   * 供应商管理-新增
   */
  FITTING_SUPPLIER_ADD: 'FITTING_SUPPLIER_ADD',
  /**
   * 供应商管理-编辑
   */
  FITTING_SUPPLIER_EDIT: 'FITTING_SUPPLIER_EDIT',
  /**
   * 供应商管理导入
   */
  FITTING_SUPPLIER_IMPORT: 'FITTING_SUPPLIER_IMPORT',
  /**
   * 供应商管理导出
   */
  FITTING_SUPPLIER_EXPORT: 'FITTING_SUPPLIER_EXPORT',

  /**
   * 商品管理菜单
   */
  GOODS_MENU: 'GOODS_MENU',

  /**
   * 新增/编辑商品
   */
  GOODS_UPSERT: 'GOODS_UPSERT',

  /**
   * 导入商品
   */
  GOODS_IMPORT: 'GOODS_IMPORT',

  /**
   * 导出商品
   */
  GOODS_EXPORT: 'GOODS_EXPORT',

  /**
   * 上架下架商品
   */
  GOODS_STATUS_CHANGE: 'GOODS_STATUS_CHANGE',

  /**
   * 服务站直供件库存监控
   */
  STATION_INVENTORY_MONITOR_MENU: 'STATION_INVENTORY_MONITOR_MENU',

  /**
   * 导入
   */
  STATION_INVENTORY_MONITOR_THRESHOLD_IMPORT:
    'STATION_INVENTORY_MONITOR_THRESHOLD_IMPORT',

  /**
   * 导出
   */
  STATION_INVENTORY_MONITOR_THRESHOLD_EXPORT:
    'STATION_INVENTORY_MONITOR_THRESHOLD_EXPORT',

  /**
   * 出库入库
   */
  STATION_INVENTORY_MONITOR_INVENTORY_CHANGE:
    'STATION_INVENTORY_MONITOR_INVENTORY_CHANGE',
  FITTING_INVENTORY_WATCHER_MENU: 'FITTING_INVENTORY_WATCHER_MENU',
  /**
   * 驻点服务站库存
   */
  RESIDENT_STATION_INVENTORY_EXPORT: 'RESIDENT_STATION_INVENTORY_EXPORT',

  /**
   * 安能已结算维保单更新
   */
  AN_NENG_MAINT_ORDER_SETTLED_IMPORT: 'AN_NENG_MAINT_ORDER_SETTLED_IMPORT',

  /**
   * 维保单导出
   */
  MAINT_ORDER_EXPORT: 'MAINT_ORDER_EXPORT',

  /**
   * 分拨点导入
   */
  DISTRIBUTION_CENTER_IMPORT: 'DISTRIBUTION_CENTER_IMPORT',

  /**
   * 分拨点导出
   */
  DISTRIBUTION_CENTER_EXPORT: 'DISTRIBUTION_CENTER_EXPORT',

  /**
   * 旧件返还（菜单）
   */
  MAINT_FITTING_RECYCLE_MENU: 'MAINT_FITTING_RECYCLE_MENU',

  /**
   * 确认收货
   */
  MAINT_FITTING_RECYCLE_CONFIRM_DELIVERED:
    'MAINT_FITTING_RECYCLE_CONFIRM_DELIVERED',

  /**
   * 确认无法返还
   */
  MAINT_FITTING_RECYCLE_NOT_RECYCLE: 'MAINT_FITTING_RECYCLE_NOT_RECYCLE',

  /**
   * 入库
   */
  MAINT_FITTING_RECYCLE_ENTERED_INVENTORY:
    'MAINT_FITTING_RECYCLE_ENTERED_INVENTORY',

  /**
   * 销毁
   */
  MAINT_FITTING_RECYCLE_DESTROYED: 'MAINT_FITTING_RECYCLE_DESTROYED',
  /**
   * 对接管理菜单
   */
  INTEGRATION_MANAGEMENT_MENU: 'INTEGRATION_MANAGEMENT_MENU',
  /**
   * 直供件库存调整
   */
  DIRECT_SUPPLY_FITTING_ADJUST: 'DIRECT_SUPPLY_FITTING_ADJUST',

  /**
   * 车队月度检车报告菜单
   */
  VEHICLE_TEAM_MONTHLY_INSPECTION_REPORT_MENU:
    'VEHICLE_TEAM_MONTHLY_INSPECTION_REPORT_MENU',

  /**
   * 运营报表菜单权限
   */
  OPERATIONS_REPORT_MENU: 'OPERATIONS_REPORT_MENU',

  /**
   * 批量下载车队月度检车报告菜单
   */
  VEHICLE_TEAM_MONTHLY_INSPECTION_REPORT_DOWNLOAD_MULTIPLY:
    'VEHICLE_TEAM_MONTHLY_INSPECTION_REPORT_DOWNLOAD_MULTIPLY',

  /**
   * 车辆检车报告菜单
   */
  VEHICLE_INSPECTION_REPORT_MENU: 'VEHICLE_INSPECTION_REPORT_MENU',

  /**
   * 导出车辆检车报告菜单
   */
  VEHICLE_INSPECTION_REPORT_EXPORT: 'VEHICLE_INSPECTION_REPORT_EXPORT',

  /**
   * 费用登记菜单
   */
  RESIDENT_FEE_MENU: 'RESIDENT_FEE_MENU',

  RESIDENT_FEE_EXPORT: 'RESIDENT_FEE_EXPORT',

  /**
   * 新增费用登记
   */
  RESIDENT_FEE_ADD: 'RESIDENT_FEE_ADD',
  /**
   * 安能星威工时配件映射导入
   */
  AN_NENG_FITTING_MODEL_MAPPING_IMPORT: 'AN_NENG_FITTING_MODEL_MAPPING_IMPORT',
  /**
   * 安能星威工时配件映射导出
   */
  AN_NENG_FITTING_MODEL_MAPPING_EXPORT: 'AN_NENG_FITTING_MODEL_MAPPING_EXPORT',

  /**
   * 开启关闭备用金账号
   */
  PETTY_CASH_ACCOUNT_STATUS_SETTING: 'PETTY_CASH_ACCOUNT_STATUS_SETTING',
  /**
   * 申请备用金临时额度
   */
  PETTY_CASH_CREDIT_APPLY: 'PETTY_CASH_CREDIT_APPLY',
  /**
   * 审批备用金临时额度
   */
  PETTY_CASH_CREDIT_APPROVED: 'PETTY_CASH_CREDIT_APPROVED',

  /**
   * 退钱退货
   */
  RESIDENT_RETURN_GOODS_MONEY: 'RESIDENT_RETURN_GOODS_MONEY',

  /**
   * 退钱不退货
   */
  RESIDENT_RETURN_MONEY: 'RESIDENT_RETURN_MONEY',

  /**
   * 保外对账
   */
  MAINT_PRE_QUOTATION_SETTLEMENT_RECONCILIATION:
    'MAINT_PRE_QUOTATION_SETTLEMENT_RECONCILIATION',
};
